import React from 'react'; 
import { withRouter } from 'react-router-dom';
import { MeasurementsService } from '../../services'; 
import Report1Page from '../map-page/Report1Page';
 

class MainPage extends React.Component {  
    
    _isMounted = false; 
   // _measurementsService = new MeasurementsService();


    state = 
    {    
        measurements: [],
        loaded: false
    }

    async componentDidMount() { 
        this._isMounted = true;          
     //   const fetchedMeasurements = await this._measurementsService.getItems();
     //   this.setState({measurements: fetchedMeasurements }); 
    }
     
    componentWillUnmount() {
        this._isMounted = false; 
    }

    render() {
        return (
            <>
            <Report1Page/>
            </>
        );
    }
}

export default withRouter(MainPage);
import React, { Component } from "react";

import { Route, Switch } from 'react-router-dom';
import LoginPage from '../../pages/login-page/';
import LogoutPage from '../../pages/logout-page/';
import MainPage from '../../pages/main-page';
import PrivateRoute from '../shared/private-route/'
import ReportPage from '../../pages/map-page/Report1Page';


import "./custom.css";
import Report1Page from "../../pages/map-page/Report1Page";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <div className="container">
      <Switch>
        <PrivateRoute exact path="/" component={MainPage} />
        <Route exact path="/login/" component={LoginPage} />
        <Route exact path="/map/" component={Report1Page} />
        

        <Route exact path="/logout/" component={LogoutPage} />
      </Switch>
      </div>
    );
  }
}

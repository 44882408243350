
import React, { useState } from 'react'; 
  import './login-page.scss';


const LoginPageView = ({isFormSubmitting, message, onFormSubmit}) => {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const _onFormSubmit = (e) => {
        e.preventDefault();
        
        if (isFormSubmitting){
            return;
        }
  
        onFormSubmit({username, password}); 
    }

    return (
        <div className="login-page text-center pt-5">  
            <form className="form-login" onSubmit={_onFormSubmit}>                   
                <h1 className="h3 mb-5 font-weight-normal sign-in-label">
                    Welcome to Kondor Reports
                </h1>
 
                <input 
                    type="text"  
                    value={username || ''}
                    onChange={(e) => setUsername(e.target.value) }
                    className="username form-control" 
                    placeholder="username"
                    required 
                    autoFocus 
                />
 
                <input 
                    type="password"  
                    value={password || ''}
                    onChange={(e) => setPassword(e.target.value) }
                    className="password form-control" 
                    placeholder="password"
                    required 
                /> 

                {
                    message &&
                    <div className="my-2 text-danger">
                         message
                    </div>
                }

                <button className="btn btn-lg btn-outline-primary btn-block" type="submit" disabled={isFormSubmitting}>
                    {
                        isFormSubmitting ? 
                        <>
                            <i className="fas fa-spinner fa-spin mr-2"></i>
                            Logining
                        </> 
                        :
                        <>
                            Login
                        </> 
                    } 
                </button> 
            </form> 
        </div>
    );
}

export default LoginPageView; 
import NetworkConfig from "./network_config.json";
import { DataSet, Network } from "vis";

export default class KondorNetwork {
  nodesStation = [];
  edgeList = [];
  dataEdge = [];
  dataNode = [];
  wdgeList = [];
  network;
  data = null;

  getIdbyStationName(name, nodesStation) {
    if(this.nodesStation.filter(x => x.stationName === name).length == 0) return null; 
    return this.nodesStation.filter(x => x.stationName === name)[0].id;
  }

  findCordinates(element, template) {
    return template.filter(e => e.title === element)[0];
  }

  prepareTrainLines(arrayOfTrainLines, nodesStation, edgeList, colorsOfLines) {
    let counterNode = 1;
    let counterEdge = 1;

    arrayOfTrainLines.forEach((arrayOfLinesElement, indexLine) => {
      arrayOfLinesElement.stations.forEach(stationsElement => {
        const isElementExist =
          nodesStation.findIndex(
            x => x.stationName === stationsElement.stationName
          ) > -1;
        if (isElementExist === false) {
          nodesStation.push({
            id: counterNode++,
            stationName: stationsElement.stationName,
            label: stationsElement.stationName,
            font: {
              strokeWidth: 3,
              color: '#8c3206',
              vadjust: -95
            }
          });
        }
      });
      var skipFirst = true;
      arrayOfLinesElement.stations.forEach((element, index) => {
        if (skipFirst) {
          skipFirst = false;
          return;
        }

        let fromNode = this.getIdbyStationName(
          arrayOfLinesElement.stations[index - 1].stationName,
          nodesStation
        );
        let toNode = this.getIdbyStationName(
          arrayOfLinesElement.stations[index].stationName,
          nodesStation
        );

        this.edgeList.push({
          id: counterEdge++,
          from: fromNode,
          to: toNode,
          title: "Keine Daten",
          width: 10,
          color: {
            color: "gray"
          }
        });
      });
    });
  }

  changeEdge(from, to, width, color, title) {
    let fromNode = this.getIdbyStationName(from);
    let toNode = this.getIdbyStationName(to);

    if(fromNode == undefined || toNode == undefined) return;

    let currentEdge = this.data.edges.get(
      this.edgeList.filter(val => val.from === fromNode && val.to === toNode)[0]
        .id
    );

    let currentEdges = this.edgeList.filter(val => val.from === fromNode && val.to === toNode);

    currentEdges.forEach(element => {
      this.data.edges.update({
        id: element.id,
        width: width,
        color: {
          color: color
        },
        title: title == undefined ? 'Keine Daten' : title + "%",
        label: title == undefined ? 'Keine Daten' : title + "%"
      });
    });

  
       
    


  
/*
    this.data.edges.update({
      id: currentEdge.id,
      width: width,
      color: {
        color: color
      }
    });
    */
  }

  insertAllInformationToNetworkGui(nodesStation, edgeList, container) {
    this.edgeList = edgeList;
    this.dataEdge = new DataSet(edgeList);
    this.dataNode = new DataSet(nodesStation);
    console.log(this.dataEdge);
    this.data = {
      nodes: this.dataNode,
      edges: this.dataEdge
    };
    return new Network(
      container,
      { nodes: this.dataNode, edges: this.dataEdge },
      NetworkConfig
    );
  }

  setXYPosition(geo, nodesStation) {
    var output = geo
      .reduce((acc, val) => acc.concat(val), [])
      .sort((a, b) => a.id - b.id);

    output = output
      .map(e => e["id"])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => output[e])
      .map(e => output[e]);

    var max_X = Math.max.apply(
      Math,
      output.map(o => o.longitude)
    );
    var min_X = Math.min.apply(
      Math,
      output.map(o => o.longitude)
    );
    var max_Y = Math.max.apply(
      Math,
      output.map(o => o.latitude)
    );
    var min_Y = Math.min.apply(
      Math,
      output.map(o => o.latitude)
    );

    var maxDeltaX = max_X - min_X;
    var maxDeltaY = max_Y - min_Y;

    var xyRatio = maxDeltaX / maxDeltaY;

    var geoTemplate = output.map(e => ({
      title: e.title,
      x: (e.longitude - min_X) / maxDeltaX,
      y: 1 - (e.latitude - min_Y) / maxDeltaY
    }));

    nodesStation.forEach(e => {
      var cord = this.findCordinates(e.label, geoTemplate);
      var em = 1300;
      e.x = cord.x * xyRatio * em;
      e.y = cord.y * xyRatio * em;
    });
  }

  createNetwork(input) {
    const generalStationInformation = input.map(({ title, stations }) => ({
      lineName: title,
      stations: stations.map(({ title }) => ({ stationName: title }))
    }));

    const geo = input.map(e => e.stations);

    this.prepareTrainLines(
      generalStationInformation,
      this.nodesStation,
      this.edgeList,
      input.map(elem => ({ id: elem.id, color: elem.color }))
    );
    this.setXYPosition(geo, this.nodesStation, this.edgeList);
    this.network = this.insertAllInformationToNetworkGui(
      this.nodesStation,
      this.edgeList,
      document.getElementById("mynetwork")
    );
  }
}

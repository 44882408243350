import React, { useState, useEffect } from 'react';
import LoginPageView from './login-page-view'; 
import { withRouter, Redirect } from 'react-router-dom';   

import { authenticationService } from '../../services/authenticationService';
  


const LoginPage = () => {    
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [message, setMessage] = useState(null); 

    useEffect(() => {        
        document.title = `Login / Kondor`;  

        return (() => {
            document.title = `Kondor`;  
        });
    }, []);
 
    const onFormSubmit = async ({username, password}) => {  
        setIsFormSubmitting(true);
        const loginResult = await authenticationService.login(username, password);
 
         
        if (loginResult.isSuccess === false) {
            setIsFormSubmitting(false); 
            setMessage(loginResult.result.message);            
        } 
    }

    const result = authenticationService.currentUserValue ? <Redirect to='/'/> :
                                <LoginPageView   
                                    isFormSubmitting = {isFormSubmitting}
                                    message = {message}
                                    onFormSubmit={onFormSubmit} />;

    return(result);
}
 
export default withRouter(LoginPage); 
import React from "react";
import Report1PageView from "./Report1PageView";
import { withRouter } from "react-router-dom";
//for timerange controller validation
import KondorNetwork from "./KondorNetwork";
import Moment from "react-moment";
import { RealtimesService } from "../../services";

class Report1Page extends React.Component {
  _isMounted = false;
  _intervall = 0;
  _network = null;
  _playMode = false;
  _pause = false;
  _resultMapper = false;
  _loadingPoint = null;
  _loadedForDay = null;
  _reloading = false;

  state = {
    isLoaded: false,
    lines: [],
    hubConnection: null,
    startDate: new Date(),
    intervallhours: 0,
    intervallminutes: 5,
    factor: 20,
    playdatetime: new Date().toDateString(),
    playdate: null,
    playdateend: null,
    displayshow: false
  };

  async componentDidMount() {
    this._isMounted = true;

    var network = new KondorNetwork();

    if (this.props.input === undefined) {
      var exampleFile = require("./examplelines.json");
      // array
      //  var sBahnLines = PassengerInfoUtil.getAllDataFromLine(exampleFile.map(e => e.title), passengerList);
      network.createNetwork(exampleFile);

      /*
            setTimeout(() => {
                network.changeEdge("Sternschanze (Messe)", "Dammtor (Messe/CCH)", 20);
            }, 3000)
            */
    } else {
      network.createNetwork(this.props.input);
    }
    this._network = network;
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this._intervall);
  }

  handleTimeAreaChange(event) {}

  handleChangeFrom = e => {
    this.setState({
      startDate: e,
      endDate: e
    });
  };

  handleChangeIntervallMinute = e => {
    e = e.replace(/\D/g, "");
    if (e != "") {
      this.setState({
        intervallminutes: e
      });
    } else {
      this.setState({
        intervallminutes: 0
      });
    }
  };

  handleChangeIntervallHour = e => {
    e = e.replace(/\D/g, "");
    if (e != "") {
      this.setState({
        intervallhours: e
      });
    } else {
      this.setState({
        intervallminutes: 0
      });
    }
  };

  handleChangeFactor = e => {
    e = e.replace(/\D/g, "");
    this.setState({
      factor: e
    });
  };

  handleChangeTo = e => {
    this.setState({
      endDate: e
    });
  };

  handlePause = () => {
    this._pause = true;
  }

  resultMapper = (e) => {
    var moment = require("moment");

    return {
      from: e.topologicalLocationFromStation,
      to: e.topologicalLocationToStation,
      width: e.relativeTotalPax,
      start: moment(e.timeRangeStart),
      end: moment(e.timeRangeEnd),
      percentage: e.relativeTotalPax
      }
  }


  reloadData = () =>
  {
    if(this._reloading) return;

    var _realtimesService = new RealtimesService();
    console.log(this.state.playdate);
    if(this.state.playdate == undefined) return;
    
    this._reloading = true; 

    _realtimesService
      .getItem(
        "?day=" +
        (this.state.playdate.toDate().getDate()) +
          "&month=" +
          (this.state.playdate.toDate().getMonth() + 1) +
          "&year=" +
          this.state.playdate.toDate().getFullYear() +
          "&hour=" +
          this.state.playdate.toDate().getHours() +
          "&minutes=" +
          this.state.playdate.toDate().getMinutes() +
          "&hoursIntervall=" +
          0 +
          "&minutesIntervall=" +
          10
      )
      .then(result => {
        var newPlayDateEnd = this.state.playdateend.clone(); 
        newPlayDateEnd.add(1, "days");

        this.setState(
        {
            playdateend: newPlayDateEnd
        });

        this._resultMapper = result.map(this.resultMapper);

        this._reloading = false; 
        
      });
  }

  reloadDataForNextDay = () =>
  {
    if(this._reloading) return;

    var _realtimesService = new RealtimesService();
    console.log(this.state.playdate);
    if(this.state.playdate == undefined) return;
    
    this._reloading = true; 

    _realtimesService
      .getItem(
        "?day=" +
        (this.state.playdate.toDate().getDate() + 1) +
          "&month=" +
          (this.state.playdate.toDate().getMonth() + 1) +
          "&year=" +
          this.state.playdate.toDate().getFullYear() +
          "&hour=" +
          12 +
          "&minutes=" +
          0 +
          "&hoursIntervall=" +
          0 +
          "&minutesIntervall=" +
          0
      )
      .then(result => {
        var newPlayDateEnd = this.state.playdateend.clone(); 
        newPlayDateEnd.add(1, "days");

        this.setState(
        {
            playdateend: newPlayDateEnd
        });

        this._resultMapper.push(result);

        this._reloading = false; 
        
      });
  }



  handlePlay = startDate => {
  

    if(this._playMode == true){
      this._pause = false;
    }
    if (this._pause) return;
    if (this._playMode) return;
    this._playMode = true;
    var _realtimesService = new RealtimesService();


       _realtimesService
      .getItem(
        "?day=" +
          startDate.getDate() +
          "&month=" +
          (startDate.getMonth() + 1) +
          "&year=" +
          startDate.getFullYear() +
          "&hour=" +
          startDate.getHours() +
          "&minutes=" +
          startDate.getMinutes() +
          "&hoursIntervall=" +
          0 +
          "&minutesIntervall=" +
          10
      )
      .then(result => {
        
        var moment = require("moment");
        var date = moment(this.state.startDate);
        var playdateend = date.clone();

        this.setState({
          playdatetime: date.format("DD.MM.YYYY, HH:mm:ss "),
          playdate: date,
          playdateend: playdateend
            .add(1, "days")
            .add(- date.hour(), "hours")
            .add(- date.minutes(), "minutes" ),
          displayshow: true

        });

        this._resultMapper = result.map(this.resultMapper);

        this._intervall = setInterval(() => {

          if (this._pause) return;


          this.state.playdate.add(this.state.minutes, "minutes");
          var date = this.state.playdate.add(this.state.factor, "seconds");

          if (date > this.state.playdateend) {
            clearInterval(this._intervall);
            
            this._playMode = false;

            return;
          }

          const lineActive = (from, to, currentDate) => {           
            return this._resultMapper.some(e => ((e.from === from && e.to === to) ||  (e.from === to && e.to === from)) && (e.start.isBefore(currentDate) && currentDate.isBefore(e.end)));
          }

          /*
          const nextDayLoaded = () => 
          {
            var nextDay = date.clone();
            nextDay.add(- nextDay.hours(), "hours");
            nextDay.add(- nextDay.minutes(), "minutes");
            nextDay.add( 1, "days");
            return this._resultMapper.some(e => nextDay.isBefore(e.start));
          }
          */

          /*
          if(!nextDayLoaded()){
              this.reloadDataForNextDay();
          }
          */ 
         

          this._resultMapper.forEach(element => {
            if (lineActive(element.from, element.to, date )) {

           // if (element.start.isBefore(date) && date.isBefore(element.end)) {
              console.log("active");
              this._network.changeEdge(
                element.from,
                element.to,
                element.width,
                "red",
                element.percentage
              );
            } else {
              console.log("inactive");
              this._network.changeEdge(element.from, element.to, 10, "grey");
            }
          });

          this.reloadData();

          this.setState({
            playdatetime: date.format("DD.MM.YYYY, HH:mm:ss "),
            playdate: date
          });
        }, 1000);
      });


    //TODO feact data from Controller
    //play it in factor values times
    //
  };

  render() {
    return (
      <Report1PageView
        {...this.state}
        handleChangeIntervallMinute={this.handleChangeIntervallMinute}
        handleChangeIntervallHour={this.handleChangeIntervallHour}
        handleChangeFrom={this.handleChangeFrom}
        handleChangeTo={this.handleChangeTo}
        handleChangeFactor={this.handleChangeFactor}
        handlePlay={this.handlePlay}
        handlePause={this.handlePause}
      />
    );
  }
}

export default withRouter(Report1Page);

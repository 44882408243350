import { BehaviorSubject } from 'rxjs';
import Configuration from '../core/configuration';
import { sendJsonData } from '../core/web-client';
 

const currentUserVariable = 'currentUser';
const currentUserSubject = new BehaviorSubject(getUserFromStorage(currentUserVariable));
   

const login = async (username, password) => {     
    //const loginUrl = Configuration.traffic.login; 
    const loginUrl = "/Users/authenticate"; 
    const response = await sendJsonData(loginUrl, {username, password}, 'POST');    
    console.log(response);
    if (response.isSuccess){
        const user = { 
            token: response.result.token,
            expires: response.result.expires,
            username: response.result.username,
            fullName: response.result.fullName,
            roleNames: response.result.roleNames            
        };
     
        saveUserInStorage(user);
        currentUserSubject.next(user);
        // updateUserToken();
 
        return {
            isSuccess: true,
            user: user
        }; 
    }
    else{
        console.log("failed");
        return {
            isSuccess: false,
            result: response
        };
    }    
}

const logout = () => { 
    localStorage.removeItem(currentUserVariable);
    currentUserSubject.next(null);
}

function saveUserInStorage(user) {
    localStorage.setItem(currentUserVariable, JSON.stringify(user));
}

function getUserFromStorage() {
    return JSON.parse(localStorage.getItem(currentUserVariable));
}

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { 
        return currentUserSubject.value 
    },
    isUserInRoles(roles) {   
        return currentUserSubject.value && currentUserSubject.value.roleNames.some(item => roles.indexOf(item) > -1)
    }
};

import React from "react";
import { withRouter } from "react-router-dom";
import "vis/dist/vis.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";

const Report1PageView = props => {
  return (
    <>
      <div
        id="mynetwork"
        className="shadow p-4 mb-4 bg-white"
        style={{ height: 550 }}
      />
      {props.displayshow ? props.playdatetime : <div></div>}
      <div className="shadow p-4 mb-4 bg-white" style={{ marginTop: `35px` }}>
        <div className="row">
          <div className="col-12">
            <div className="col-1">From</div>
            <div className="col-3">
              <DatePicker
                selected={props.startDate}
                onChange={props.handleChangeFrom}
                timeFormat="HH:mm"
                dateFormat="  dd. MMMM yyyy H:mm 'Uhr'"
                locale={de}
                todayButton={"Heute"}
                withPortal
                showTimeSelect
                timeCaption="Zeit"
              />
            
            </div>
            <div className="col-3">
              Factor 
              <br></br>
              <input value={props.factor} onChange={(e) =>  props.handleChangeFactor(e.currentTarget.value)}></input>          
            </div>
            <br></br>

            <button onClick={() => props.handlePlay(props.startDate)}>Play</button>
            <button onClick={() => props.handlePause(props.startDate)}>Pause</button>

          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Report1PageView);

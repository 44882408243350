import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './components/app/App'
import registerServiceWorker from './registerServiceWorker';

import './styles/bootstrap-sticky-footer.scss';
import './styles/custom.scss';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
     <App/>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

